import { appHeaderEvents } from '@/components/AppHeader';
import { useAuthStore, authActionSets } from '@/lib/drupal/stores/auth';
import posthog from 'posthog-js';

export function useAuthMethods () {
  const [ authState, _authDispatch, authExecute ] = useAuthStore();

  const login = () => {
    if (!authState.ready) return;
    if (authState.ready && authState.auth) throw new Error('User is already logged in.');
    appHeaderEvents.trigger('showLogin');
  };

  const logout = async () => {
    if (!authState.ready) return;
    if (authState.ready && !authState.auth) throw new Error('User is not logged in.');
    await authExecute(authActionSets.deauthorize());
    posthog.reset();
  };

  return {
    login,
    logout,
  };
}
